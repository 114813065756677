import React from 'react';
import Carousel from './components/Carousel';
import Navigationbar from './components/Navbar';
import About from './components/AboutUs';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Registration from './components/Registration';
import Committee from './components/Committee';
import EventDetails from './components/EventDetails';
import Contact from './components/Contact';
import ForAuthors from './components/ForAuthors';
import ImportantDates from './components/ImportantDates';
import PaperSubmission from './components/PaperSubmission';
import Footer from './components/Footer';
import Speakers from './components/Speakers';
import Sponsorship from './components/Sponsorship';
import Announcements from './components/Announcements';
import Gallery from './components/Gallery';

function App() {
  return (
    <BrowserRouter>
      <div className="App flex flex-col min-h-screen">
        <Navigationbar />
        <main className="flex-grow">
          <Routes>
            <Route path='/' element={<Carousel />} />
            <Route path='/about' element={<About />} />
            <Route path='/registration' element={<Registration />} />
            <Route path='/committee' element={<Committee />} />
            <Route path='/speakers' element={<Speakers />} />
            <Route path='/sponsorship' element={<Sponsorship />} />
            <Route path='/authors' element={<ForAuthors />} />
            <Route path='/event-details' element={<EventDetails />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/announcements' element={<Announcements />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path="/important-dates" element={<ImportantDates />} />
            <Route path="/paper-submission" element={<PaperSubmission />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
