import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Squash as Hamburger } from 'hamburger-react';

const HeaderWithLogosAndNavbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    if (isMobileMenuOpen) {
      setIsDropdownOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header>
      {/* Top Section with Logos */}
      <div className="bg-white py-4 px-4 flex flex-col md:flex-row justify-between items-center">
        <div className="flex items-center space-x-4 mb-4 md:mb-0">
          <div>
            <a href="https://www.etis-2025.org">
              <img src="/images/logo1.png" alt="Left Logo" className="h-10" />
            </a>
          </div>
          <div className="flex flex-col text-center">
            <p className="font-bold text-lg">APJAKTU ETIS 2025</p>
            <p className="text-xs font-bold">EMERGING TECHNOLOGIES FOR INTELLIGENT SYSTEMS</p>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4 md:flex-row md:space-y-0 md:space-x-4">
          <a href="https://ias.ieee.org/" target="_blank" rel="noopener noreferrer">
            <img src="/images/logo4.svg" alt="Fourth Logo" className="h-12" />
          </a>
          <a href="https://ktu.edu.in" target="_blank" rel="noopener noreferrer">
            <img src="/images/logo2.png" alt="Right Logo 1" className="h-12" />
          </a>
          <a href="https://www.ieee.org" target="_blank" rel="noopener noreferrer">
            <img src="/images/logo3.svg" alt="Right Logo 2" className="h-10" />
          </a>
        </div>
      </div>

      {/* Navbar */}
      <nav className="bg-blue-600 relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              {/* Desktop Menu */}
              <div className="hidden md:flex w-full justify-between">
                <div className="ml-10 flex items-baseline space-x-1 w-full">
                  <Link to="/" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-sky-300">
                    Home
                  </Link>
                  <Link to="/about" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                    About&nbsp;Us
                  </Link>
                  <div className="relative">
                    <button
                      onClick={handleDropdownToggle}
                      className="text-white px-3 py-2 rounded-md text-sm font-medium flex items-center hover:text-cyan-400"
                    >
                      For&nbsp;Authors
                      <ChevronDownIcon className="w-5 h-5 ml-1" />
                    </button>
                    {isDropdownOpen && (
                      <div
                        ref={dropdownRef}
                        className="absolute z-50 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                      >
                        <Link
                          to="/important-dates"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-cyan-400"
                          onClick={() => setIsDropdownOpen(false)}
                        >
                          Important Dates
                        </Link>
                        <Link
                          to="/paper-submission"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-cyan-400"
                          onClick={() => setIsDropdownOpen(false)}
                        >
                          Paper Submission
                        </Link>
                      </div>
                    )}
                  </div>
                  <Link to="/committee" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                    Committees
                  </Link>
                  <Link to="/speakers" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                    Speakers
                  </Link>
                  <Link to="/event-details" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                  Industry&nbsp;Sessions
                  </Link>
                  <Link to="/sponsorship" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                    Sponsorship
                  </Link>
                  <Link to="/registration" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                    Registration
                  </Link>
                  <Link to="/contact" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                    Contact
                  </Link>
                  <Link to="/announcements" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                    Announcements
                  </Link>
                  <Link to="/gallery" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                    Gallery
                  </Link>
                </div>
              </div>
            </div>
            {/* Mobile Menu Hamburger */}
            <div className="-mr-2 flex md:hidden relative z-40">
              <Hamburger
                toggled={isMobileMenuOpen}
                toggle={setIsMobileMenuOpen}
                onToggle={handleMobileMenuToggle}
                size={24}
                duration={0.5}
                color="#ffffff"
              />
            </div>
          </div>
        </div>

    {/* Mobile menu */}
    {isMobileMenuOpen && (
          <div className="absolute top-16 left-0 w-full bg-blue-600 text-white z-30">
            <div className="flex flex-col items-center space-y-4 py-4">
              <Link
                to="/"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Home
              </Link>
              <Link
                to="/about"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                About Us
              </Link>
              <div className="relative">
                <button
                  onClick={handleDropdownToggle}
                  className="text-white px-3 py-2 rounded-md text-sm font-medium flex items-center hover:text-cyan-400"
                >
                  For Authors
                  <ChevronDownIcon className="w-5 h-5 ml-1" />
                </button>
                {isDropdownOpen && (
                  <div
                    ref={dropdownRef}
                    className="absolute z-50 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                  >
                    <Link
                      to="/important-dates"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-cyan-400"
                      onClick={() => {
                        setIsDropdownOpen(false);
                        handleMobileMenuToggle();
                      }}
                    >
                      Important Dates
                    </Link>
                    <Link
                      to="/paper-submission"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-cyan-400"
                      onClick={() => {
                        setIsDropdownOpen(false);
                        handleMobileMenuToggle();
                      }}
                    >
                      Paper Submission
                    </Link>
                  </div>
                )}
              </div>
              <Link
                to="/committee"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Committees
              </Link>
              <Link
                to="/speakers"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Speakers
              </Link>
              <Link
                to="/event-details"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Industry&nbsp;Sessions
              </Link>
              <Link
                to="/sponsorship"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Sponsorship
              </Link>
              <Link
                to="/registration"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Registration
              </Link>
              <Link
                to="/contact"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Contact
              </Link>
              <Link
                to="/announcements"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Announcements
              </Link>
              <Link
                to="/gallery"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Gallery
              </Link>
            </div>
          </div>
        )}

      </nav>

   {/* White strip with marquee effect */}
    <div className="bg-white py-1">
      <div className="overflow-hidden whitespace-nowrap">
        <marquee className="text-green-800 font-semi-bold text-xl">
        Thank you for making this conference a success! Your support and participation made it unforgettable.
        </marquee>
      </div>
    </div>

    <div
  className="bg-gray-200 bg-opacity-80 py-2 text-center shadow-md text-sm"
  style={{ backgroundColor: 'rgba(229, 231, 235, 0.8)' }}
>
  <div className="flex flex-col items-center gap-4">
    {/* Registration Section */}
    <div className="flex flex-col md:flex-row items-center justify-center gap-4 border-b border-blue-300 pb-2">
      {/* To Register Section */}
      <div className="flex items-center gap-2">
        <span className="text-blue-700 font-semibold text-sm md:text-base">
          📋 To Register:
        </span>
        <a
          href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?saralID=-912222950"
          target="_blank"
          rel="noopener noreferrer"
          className="px-3 py-1 text-xs text-white bg-blue-600 font-semibold rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
        >
          Click Here
        </a>
      </div>

      {/* Registration Instructions Section */}
      <div className="flex items-center gap-2">
        <span className="text-blue-700 font-semibold text-sm md:text-base">
          📝 Registration Instructions:
        </span>
        <a
          href="/RegistrationInstructions.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="px-3 py-1 text-xs text-white bg-blue-600 font-semibold rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
        >
          View Here
        </a>
      </div>
    </div>

    {/* Camera Ready Paper Section */}
    <div className="flex flex-col md:flex-row items-center justify-center gap-4 border-b border-blue-300 pb-2">
      {/* To Submit Paper Section */}
      <div className="flex items-center gap-2">
        <span className="text-blue-700 font-semibold text-sm md:text-base">
          📄 To Submit Camera Ready Paper:
        </span>
        <a
          href="https://cmt3.research.microsoft.com/ETIS2025/Submission/Index"
          target="_blank"
          rel="noopener noreferrer"
          className="px-3 py-1 text-xs text-white bg-blue-600 font-semibold rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
        >
          Click Here
        </a>
      </div>

      {/* Camera Ready Instructions Section */}
      <div className="flex items-center gap-2">
        <span className="text-blue-700 font-semibold text-sm md:text-base">
          📚 Camera Ready Paper Instructions:
        </span>
        <button
          onClick={() => setIsModalOpen(true)}
          className="px-3 py-1 text-xs text-white bg-blue-600 font-semibold rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
        >
          View Here
        </button>
      </div>
    </div>

    {/* New Sections for Day-wise Event Details and Paper Presentation Schedule */}
    <div className="flex flex-col md:flex-row items-center justify-center gap-4">
      {/* Day-wise Event Details */}
      <div className="flex items-center gap-2">
        <span className="text-blue-700 font-semibold text-sm md:text-base">
          🕒 Day-wise Conference Schedule:
        </span>
        <a
          href="/ETIS 2025_ Program schedule.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="px-3 py-1 text-xs text-white bg-blue-600 font-semibold rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
        >
          View Here
        </a>
      </div>

      {/* Paper Presentation Schedule */}
      <div className="flex items-center gap-2">
        <span className="text-blue-700 font-semibold text-sm md:text-base">
          🕒 Paper Presentation Schedule:
        </span>
        <a
          href="/PAPER PRESENTATION SCHEDULE WITH AUTHOR DETAILS.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="px-3 py-1 text-xs text-white bg-blue-600 font-semibold rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
        >
          View Here
        </a>
      </div>
    </div>

    {/* Updated Presentation Instructions Section with Blinking */}
    <div className="flex flex-col md:flex-row items-center justify-center gap-4">
      <div className="flex items-center gap-2"> {/* Added animate-blink class */}
        <span className="text-blue-700 font-semibold text-sm md:text-base">
          🎤 Presentation Instructions:
        </span>
        <a
          href="/Presentation instructions_ ETIS2025.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="px-3 py-1 text-xs text-white bg-blue-600 font-semibold rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
        >
          View Here
        </a>
      </div>
    </div>
  </div>
</div>




  {/* Modal */}
  {isModalOpen && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl overflow-y-auto max-h-[80%]">
        <h2 className="text-2xl font-bold mb-4">Camera Ready Paper Submission Instructions</h2>
        <div className="text-left text-gray-700">
          <p className="mb-4">
            <strong>Step 1: Final Camera-ready paper preparation</strong>
            <br />
            Please follow the IEEE two-column A4 size standard conference format for preparing your final
            manuscript. For more details, please visit the IEEE Templates page: 
            <a
              href="https://www.ieee.org/conferences/publishing/templates.html"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline hover:text-blue-800"
            >
              https://www.ieee.org/conferences/publishing/templates.html
            </a>.
            <br />
            Revised Version: Revise your paper based on reviewers’ comments/suggestions. The reviewer’s
            comments are available on the same Microsoft CMT site.
            <br />
            The maximum page limit is six pages, including references. Do not include an author biography at the
            end. If additional pages are necessary, up to 8 pages are permitted subject to an extra payment as
            mentioned in the conference website.
            <br />
            Similarity with other papers should be low (a similarity index below 30% is acceptable for
            publication). The maximum file size allowed is 3 MB, and the file format should be PDF (.pdf) without
            encryption or passwords.
            <br />
            Authors are responsible for ensuring that the reviewers’ and meta-reviewers’ comments have been
            addressed in the final manuscript.
          </p>

          <p className="mb-4">
            <strong>Step 2: IEEE Copyright Notice</strong>
            <br />
            An appropriate copyright notice is to appear at the bottom of the first page of each paper. Replace
            the copyright notice in the camera-ready paper template with the correct copyright notice as
            follows:
            <br />
            For papers in which all authors are employed by the US government, the copyright notice is: “U.S.
            Government work not protected by U.S. copyright.”
            <br />
            For papers in which all authors are employed by a Crown government (UK, Canada, and Australia),
            the copyright notice is: “979-8-3315-0754-1/25/$31.00 ©2025 Crown”.
            <br />
            For papers in which all authors are employed by the European Union, the copyright notice is: “979-8-
            3315-0754-1/25/$31.00©2025 European Union”.
            <br />
            For all other papers, the copyright notice is: “979-8-3315-0754-1/25/$31.00 ©2025 IEEE”.
          </p>

          <p className="mb-4">
            <strong>Step 3: PDF eXpress check</strong>
            <br />
            After revising your paper, pass it through PDF eXpress using conference ID (64005X) at the following
            URL: 
            <a
              href="https://ieee-pdf-express.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline hover:text-blue-800"
            >
              https://ieee-pdf-express.org/
            </a>.
            If you do not have an account, click on the link 
            <a
              href="https://ieee-pdf-express.org/account/signup"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline hover:text-blue-800"
            >
              https://ieee-pdf-express.org/account/signup
            </a> and provide your information (email address and password).
          </p>

          <p className="mb-4">
            <strong>Step 4: Camera-ready Paper Submission</strong>
            <br />
            Submit the final version of the paper (after passing it through PDF eXpress check) through the author
            console in CMT. Please note that you are expected to retain the original paper’s title in the final
            version, and the author list cannot be changed in the final manuscript.
          </p>

          <p className="mb-4">
            <strong>Step 5: Submission of the copyright form</strong>
            <br />
            IMPORTANT: Ensure that all authors have registered in CMT with their correct names and contact
            emails. IEEE e-copyright takes information from CMT, and any discrepancies with the names
            mentioned in the paper may result in the submission being removed from the conference publication
            and not uploaded to IEEE Xplore. Submitting e-copyright is a one-time process and cannot be
            corrected. For corrections, authors must contact IEEE directly; the conference cannot assist in this
            regard.
            <br />
            After uploading the final camera-ready paper (which must have passed PDF Check in IEEE PDF
            eXpress), click on the ‘Submit IEEE Copyright Form’ link to access the IEEE Copyright Form
            Submission CMT page. Click on the IEEE copyright website link. You will be redirected to the IEEE eCF
            site to submit the copyright form. After completing the IEEE copyright form and copyright
            agreements, you will have the option to download the completed IEEE Copyright Form (the
            corresponding author will also receive the copyright form via email). Please upload it to the same
            page in CMT or use the ‘drop files here’ menu. The corresponding author may submit the copyright
            form for all the authors of the paper.
          </p>
        </div>
        <button
          onClick={() => setIsModalOpen(false)}
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors"
        >
          Close
        </button>
      </div>
    </div>
  )}

  <style jsx>{`
    @keyframes blink {
      0% { opacity: 1; }
      50% { opacity: 0; }
      100% { opacity: 1; }
    }

    .animate-blink {
      animation: blink 1.5s linear infinite;
    }
  `}</style>
    </header>
  );
};

export default HeaderWithLogosAndNavbar;