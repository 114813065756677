import React, { useState, useEffect } from 'react';

const Carousel = () => {
  const calculateTimeLeft = () => {
    const eventDate = new Date('2025-02-07T00:00:00').getTime();
    const now = new Date().getTime();
    const difference = eventDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const svgStyle = {
    animation: 'moveGraphic 20s ease-in-out infinite',
    position: 'absolute',
    top: 0,
    left: '-20%',
    width: '140vw',
    height: '140vh',
    objectFit: 'cover',
    transformOrigin: 'center center',
    transform: 'scale(1.3)',
  };

  return (
    <div className="w-full">
      <style>
        {`
          @keyframes moveGraphic {
            0% {
              transform: translateX(0) scale(1.3);
            }
            50% {
              transform: translateX(-5%) scale(1.3);
            }
            100% {
              transform: translateX(0) scale(1.3);
            }
          }
        `}
      </style>

      {/* Section 1: SVG Art and Event Details */}
      <section className="relative w-full h-screen overflow-hidden bg-blue-800 text-white">
        <svg
          viewBox="0 0 1600 900"
          xmlns="http://www.w3.org/2000/svg"
          style={svgStyle}
        >
          <path
            d="M0 450C300 250 600 50 900 250C1200 450 1500 250 1800 450V900H0V450Z"
            fill="#1E3A8A"
          />
          <path
            d="M0 450C300 150 600 -50 900 150C1200 350 1500 150 1800 250V900H0V450Z"
            fill="#2563EB"
            opacity="0.7"
          />
          <path
            d="M100 450C400 200 700 0 1000 200C1300 400 1600 200 1900 450V900H100V450Z"
            fill="#3B82F6"
            opacity="0.5"
          />
          <circle cx="900" cy="450" r="360" fill="#BFDBFE" opacity="0.3" />
        </svg>
        <div className="absolute inset-0 flex items-center justify-center text-center">
          <div className="relative z-10">
            <h1 className="text-3xl md:text-5xl font-bold">
              International Conference on <br /> Emerging Technologies for Intelligent Systems <br /> (ETIS 2025)
            </h1>
            <p className="mt-4 text-lg md:text-2xl font-semibold">
              Dates: 7, 8, 9 February 2025<br /> Venue: Mar Baselios College of Engineering And Technology, Trivandrum
            </p>
          </div>
        </div>
      </section>

{/* Section 2: Sponsors */}
<section className="py-16 bg-gradient-to-b from-gray-50 to-gray-100">
  <div className="container mx-auto px-4">
    <h2 className="text-4xl font-bold text-center mb-12 text-blue-900">
      Our Valued Sponsors
      <span className="block mt-2 text-blue-600 text-xl font-medium">Proudly Supported By</span>
    </h2>

    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      {/* Gold Sponsor */}
      <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300">
        <div className="mb-4">
          <span className="bg-gradient-to-r from-yellow-400 to-yellow-500 text-white px-4 py-1 rounded-full text-sm font-bold">
            Gold&nbsp;Partner&nbsp;(5&nbsp;Lakhs)
          </span>
        </div>
        <div className="flex flex-col items-center justify-center h-48">
          <img 
            src="/sponsors/cochin-shipyard.jpg" 
            alt="Cochin Shipyard" 
            className="h-24 object-contain mb-4 transition-transform duration-300 hover:scale-105"
          />
          <p className="text-lg font-semibold text-gray-800 text-center">Cochin Shipyard Limited</p>
        </div>
      </div>

      {/* Silver Sponsors */}
      <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300">
        <div className="mb-4">
          <span className="bg-gradient-to-r from-gray-400 to-gray-500 text-white px-4 py-1 rounded-full text-sm font-bold">
            Silver&nbsp;Partners&nbsp;(3&nbsp;Lakhs)
          </span>
        </div>
        <div className="space-y-6">
          {[
            { name: 'Ospyn Technologies', image: 'ospyn' },
            { name: 'Turnitin', image: 'turnitin' }
          ].map((sponsor) => (
            <div key={sponsor.image} className="flex flex-col items-center p-3 bg-gray-50 rounded-lg">
              <img
                src={`/sponsors/${sponsor.image}.png`}
                alt={sponsor.name}
                className="h-16 w-24 object-contain mb-2"
              />
              <span className="text-sm font-semibold text-gray-700 text-center">{sponsor.name}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Bronze Sponsors */}
      <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300">
        <div className="mb-4">
          <span className="bg-gradient-to-r from-orange-400 to-orange-500 text-white px-4 py-1 rounded-full text-sm font-bold">
            Bronze&nbsp;Partners&nbsp;(2&nbsp;Lakhs)
          </span>
        </div>
        <div className="grid grid-cols-1 gap-4">
          {[
            { name: 'KELTRON', image: 'keltron' },
            { name: 'KSPACE', image: 'kspace' },
            { name: 'KSITL', image: 'ksitl' },
            { name: 'SBI', image: 'sbi' }
          ].map((sponsor) => (
            <div key={sponsor.image} className="flex flex-col items-center p-2 bg-gray-50 rounded-lg">
              <img
                src={`/sponsors/${sponsor.image}.png`}
                alt={sponsor.name}
                className="h-12 w-20 object-contain mb-1"
              />
              <span className="text-xs font-semibold text-gray-700 text-center">{sponsor.name}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Brass Sponsors */}
      <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300">
        <div className="mb-4">
          <span className="bg-gradient-to-r from-gray-600 to-gray-700 text-white px-4 py-1 rounded-full text-sm font-bold">
            Brass&nbsp;Partners&nbsp;(1&nbsp;Lakh)
          </span>
        </div>
        <div className="grid grid-cols-2 gap-3">
          {[
            { name: 'Carestack', image: 'carestack', ext: 'jpeg' },
            { name: 'Typhoon HIL', image: 'typhoon-hil', ext: 'jpeg' },
            { name: 'IT Mission', image: 'it-mission' },
            { name: 'CDAC', image: 'cdac' },
            { name: 'Wadhwani Foundation', image: 'wadhwani' },
            { name: 'Federal Bank', image: 'federalbank' },
            { name: 'Keyvalue', image: 'keyvalue', ext: 'svg'},
            { name: 'Mathworks', image: 'mathworks',},
            { name: 'KSEB', image: 'kseb'},
            { name: 'ANERT', image: 'anert'}
          ].map((sponsor) => (
            <div key={sponsor.image} className="flex flex-col items-center p-2 bg-gray-50 rounded-lg">
              <img
                src={`/sponsors/${sponsor.image}.${sponsor.ext || 'png'}`}
                alt={sponsor.name}
                className="h-10 object-contain mb-1"
              />
              <span className="text-[0.7rem] font-semibold text-gray-700 text-center leading-tight">
                {sponsor.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
</section>


      {/* Section 3: Countdown Timer */}
      <section className="py-12 bg-black text-white text-center">
        <h2 className="text-lg md:text-2xl font-medium mb-4">Countdown to ETIS 2025</h2>
        <div className="flex justify-center space-x-4 md:space-x-8">
          <div>
            <span className="text-xl md:text-3xl font-medium">{timeLeft.days || '0'}</span>
            <p className="text-xs md:text-sm">Days</p>
          </div>
          <div>
            <span className="text-xl md:text-3xl font-medium">{timeLeft.hours || '0'}</span>
            <p className="text-xs md:text-sm">Hours</p>
          </div>
          <div>
            <span className="text-xl md:text-3xl font-medium">{timeLeft.minutes || '0'}</span>
            <p className="text-xs md:text-sm">Minutes</p>
          </div>
          <div>
            <span className="text-xl md:text-3xl font-medium">{timeLeft.seconds || '0'}</span>
            <p className="text-xs md:text-sm">Seconds</p>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Carousel;