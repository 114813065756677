import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

const images = [  
  "/gallery/1.jpg",  
  "/gallery/2.jpg",  
  "/gallery/3.jpg",  
  "/gallery/4.jpg",  
  "/gallery/5.heic",  
  "/gallery/6.jpg",  
  "/gallery/7.jpg",  
  "/gallery/8.jpg",  
  "/gallery/9.jpg",  
  "/gallery/10.jpg",  
  "/gallery/11.jpg",  
  "/gallery/12.jpg",  
  "/gallery/13.jpg",  
  "/gallery/14.jpg",  
  "/gallery/15.jpg",  
  "/gallery/16.jpg",  
  "/gallery/17.jpg",  
  "/gallery/18.jpg",  
  "/gallery/19.jpg",  
  "/gallery/20.jpg",  
  "/gallery/21.jpg",  
  "/gallery/22.jpg",  
  "/gallery/23.jpg",  
  "/gallery/24.jpg",  
  "/gallery/25.jpg",  
  "/gallery/26.jpg",  
  "/gallery/27.jpg"  
];


const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [convertedImages, setConvertedImages] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Handle HEIC conversion
  const convertHeicToJpg = async (src) => {
    if (!src.endsWith('.heic')) return src;
    if (convertedImages[src]) return convertedImages[src];

    try {
      const heic2any = (await import('heic2any')).default;
      const response = await fetch(src);
      const blob = await response.blob();
      const conversionResult = await heic2any({ blob, toType: 'image/jpeg' });
      const url = URL.createObjectURL(conversionResult);
      setConvertedImages(prev => ({ ...prev, [src]: url }));
      return url;
    } catch (error) {
      console.error('HEIC conversion error:', error);
      return src;
    }
  };

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isOpen) {
        if (e.key === 'Escape') closeImage();
        if (e.key === 'ArrowRight') nextImage();
        if (e.key === 'ArrowLeft') prevImage();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, currentIndex]);

  const nextImage = () => setCurrentIndex(prev => (prev + 1) % images.length);
  const prevImage = () => setCurrentIndex(prev => (prev - 1 + images.length) % images.length);

  const openImage = async (index) => {
    setIsLoading(true);
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeImage = () => {
    setIsOpen(false);
    setIsLoading(true);
  };

  // Touch handling for mobile swipe
  const [touchStart, setTouchStart] = useState(0);
  const handleTouchStart = (e) => setTouchStart(e.touches[0].clientX);
  const handleTouchEnd = (e) => {
    const touchEnd = e.changedTouches[0].clientX;
    if (touchStart - touchEnd > 50) nextImage();
    if (touchStart - touchEnd < -50) prevImage();
  };

  return (
    <section className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
      <Helmet>
        <link rel="preload" as="image" href={images[0]} />
      </Helmet>

      <h2 className="text-4xl font-bold text-blue-600 mb-8 text-center">
        Gallery
      </h2>

      {/* Main Slider */}
      <div className="relative group mb-8">
        <div 
          className="relative aspect-video bg-gray-100 rounded-xl shadow-xl overflow-hidden transition-transform duration-300 hover:shadow-2xl"
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <button
            onClick={prevImage}
            className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 p-3 rounded-full shadow-md hover:bg-white transition-all opacity-0 group-hover:opacity-100"
            aria-label="Previous image"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>

          <img
            src={images[currentIndex]}
            alt={`Gallery item ${currentIndex + 1}`}
            className={`w-full h-full object-cover cursor-zoom-in transition-opacity duration-300 ${isLoading ? 'opacity-0' : 'opacity-100'}`}
            onClick={() => openImage(currentIndex)}
            onLoad={() => setIsLoading(false)}
            loading="lazy"
          />

          <button
            onClick={nextImage}
            className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 p-3 rounded-full shadow-md hover:bg-white transition-all opacity-0 group-hover:opacity-100"
            aria-label="Next image"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>

        {/* Thumbnail Strip */}
        <div className="mt-4 flex gap-2 overflow-x-auto pb-4">
          {images.map((img, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`flex-shrink-0 w-20 h-14 rounded-lg overflow-hidden border-2 ${
                index === currentIndex ? 'border-blue-500' : 'border-transparent'
              }`}
            >
              <img
                src={img}
                alt={`Thumbnail ${index + 1}`}
                className="w-full h-full object-cover"
                loading="lazy"
              />
            </button>
          ))}
        </div>
      </div>

      {/* Fullscreen Modal */}
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black/90 backdrop-blur-lg z-50 flex items-center justify-center"
          onClick={closeImage}
        >
          <div className="relative w-full h-full flex items-center justify-center">
            <button
              className="absolute top-4 right-4 text-white/80 hover:text-white p-2"
              onClick={closeImage}
              aria-label="Close"
            >
              <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            <div className="relative max-w-[90vw] max-h-[90vh]">
              <img
                src={convertedImages[images[currentIndex]] || images[currentIndex]}
                alt="Fullscreen view"
                className={`max-w-full max-h-full object-contain rounded-lg ${isLoading ? 'opacity-0' : 'opacity-100'}`}
                onLoad={async () => {
                  if (images[currentIndex].endsWith('.heic')) {
                    const converted = await convertHeicToJpg(images[currentIndex]);
                    if (converted) setIsLoading(false);
                  } else {
                    setIsLoading(false);
                  }
                }}
              />

              {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent" />
                </div>
              )}

              <div className="absolute bottom-8 left-1/2 -translate-x-1/2 bg-black/50 text-white px-4 py-2 rounded-full">
                {currentIndex + 1} / {images.length}
              </div>
            </div>

            <button
              className="absolute left-4 top-1/2 -translate-y-1/2 text-white/80 hover:text-white p-4"
              onClick={(e) => { e.stopPropagation(); prevImage(); }}
              aria-label="Previous image"
            >
              <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>

            <button
              className="absolute right-4 top-1/2 -translate-y-1/2 text-white/80 hover:text-white p-4"
              onClick={(e) => { e.stopPropagation(); nextImage(); }}
              aria-label="Next image"
            >
              <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Gallery;